.is-boxed {
  background: color-bg(body-outer);

  .body-wrap {
    max-width: $container--width-boxed;
    margin: 0 auto;
    box-shadow: color-bg(body-shadow);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: color-bg(body);
      z-index: -10;
    }
  }
}

.body-wrap {
  position: relative;
  overflow: hidden;
  // Sticky footer
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-content {
  flex: 1 0 auto;
}
.split-img {
  border-radius: 8px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.down {
  position: absolute;
  bottom: 32%;
  right: 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-size: 14px;
}
.down::before {
  content: "";
  position: absolute;
  bottom: -18px;
  left: -36px;
  width: 1px;
  height: 55px;
  background-color: #fbf4e0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
img {
  max-height: 100%;
}
